import { CacheType, createObservableDataAction, IAction, IActionContext, IActionInput, ICommerceApiSettings, ICreateActionContext } from '@msdyn365-commerce/core';
import { Cart } from '@msdyn365-commerce/retail-proxy';
import { copyAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getCartState } from './get-cart-state';

/**
 * Input class for getCheckoutCart data action
 */
export class GetCheckoutCartInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => `CheckoutCart-chanId:${this.apiSettings.channelId}-catId:${this.apiSettings.catalogId}`;
    public getCacheObjectType = () => 'CheckoutCart';
    public dataCacheType = (): CacheType => 'request';
}

const createInput = (inputData: ICreateActionContext) => {
    return new GetCheckoutCartInput(inputData.requestContext.apiSettings);
};

/**
 * Data action to copy the cart for checkout actions
 */
export async function getCheckoutCart(input: GetCheckoutCartInput, ctx: IActionContext): Promise<Cart | undefined> {
    const cartState = await getCartState(ctx);

    if (cartState && cartState.cart.Id !== undefined) {
        return copyAsync({ callerContext: ctx }, cartState.cart.Id, 2)
            .then(cart => cart)
            .catch(error => {
                ctx.telemetry.error('Error copying cart');
                ctx.telemetry.exception(error);
                return undefined;
            });
    }

    return undefined;
}

export default createObservableDataAction({
    id: '@msdyn365-commerce/global-state/get-checkout-cart',
    action: <IAction<Cart | undefined>>getCheckoutCart,
    input: createInput
});