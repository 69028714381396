import { IActionContext } from '@msdyn365-commerce/core';
import { Address, Cart, CartLine, OrgUnitLocation, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { action, computed, observable } from 'mobx';

import { GlobalState } from '../global-state/global-state';
import { ICartActionResult, ICartActionSubStatus, ICartState } from '../state-interfaces/i-cart-state';
import addProductToCartInternal from './add-product-to-cart';
import addPromoCodeInternal from './add-promo-code';
import { ICartActionResultWithCart } from './cart-action-result';
import clearCartLineDeliveryModeInternal from './clear-cart-line-delivery-mode';
import getOrCreateActiveCart from './get-or-create-active-cart';
import refreshCartInternal from './refresh-cart';
import removeAllPromoCodesInternal from './remove-all-promo-codes';
import removeCartLineInternal from './remove-cart-lines';
import removePromoCodesInternal from './remove-promo-codes';
import updateCartLineDeliverySpecificationsInternal from './update-cart-line-delivery-specifications';
import updateCartLineQuantityInternal from './update-cart-line-quantity';
import updateLoyaltyCardIdInternal from './update-loyalty-card-id';
import clearCartLinesDeliveryInformation from './clear-cart-lines-delivery-information'

/**
 * Cart state information
 */
export class BaseCartState extends GlobalState implements ICartState {
    @observable protected _cart: Cart;

    @computed public get cart(): Readonly<Cart> {
        return this._cart;
    }

    @computed public get totalItemsInCart(): number {
        if (this._cart.CartLines) {
            return this._cart.CartLines.map(cartLine => cartLine.Quantity || 1).reduce((total, num) => total + num, 0);
        }

        return 0;
    }

    @computed public get isEmpty(): boolean {
        return !(this._cart.CartLines && this._cart.CartLines.length > 0);
    }

    constructor(actionContext: IActionContext) {
        super(actionContext);
        this._cart = <Cart>{};
    }

    public async initialize(): Promise<void> {
        if (this.isInitialized) {
            return;
        }

        const newCart = await getOrCreateActiveCart(this.actionContext);

        if (newCart) {
            this._cart = newCart;
            this._status = 'READY';
        } else {
            this._status = 'ERROR';
        }

        this.isInitialized = true;
    }

    /**
     * Refreshes the cart by getting it from the server
     *
     * Other actions should keep cart up to date so shouldn't need to call this
     * outside of initialization but still might be scenarios where a manual
     * refresh is needed
     */
    @action
    public async refreshCart(input: {additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            const newCart = await refreshCartInternal(this.cart.Id, this.actionContext);

            if (newCart) {
                this._cart = newCart;

                return { status: 'SUCCESS'};
            }

            return { status: 'FAILED'};
        });
    }


    /**
     * Adds the specified product to the current cart. If product is already in cart
     * will update its cart line, otherwise will add a new cart line to the cart
     *
     * @param product The product to add to the cart
     * @param count: How many copies of the product to add
     * @param location: The org unit location, used for BuyOnlinePickupInStore scenarios
     * (If you want item to simply be shipped, leave this parameter undefined)
     */
    @action
    public async addProductToCart(input: {product: SimpleProduct; count?: number; location?: OrgUnitLocation; additionalProperties?: object; availableQuantity?: number; enableStockCheck?: boolean}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            const cartLine: CartLine = {
                CatalogId: this.actionContext.requestContext.apiSettings.catalogId,
                Description: input.product.Description,
                // TODO: Investigate this value and what it represents
                EntryMethodTypeValue: 3,
                ItemId: input.product.ItemId,
                ProductId: input.product.RecordId,
                Quantity: input.count || 1,
                TrackingId: '',
                UnitOfMeasureSymbol: input.product.DefaultUnitOfMeasure
            };

            if (input.location) {
                if (!this.actionContext.requestContext.channel) {
                    return { status: 'FAILED'};
                }

                cartLine.DeliveryMode = this.actionContext.requestContext.channel.PickupDeliveryModeCode;
                cartLine.FulfillmentStoreId = input.location.OrgUnitNumber;
                cartLine.ShippingAddress = this._buildAddressFromOrgUnitLocation(input.location);
            }

            return this._doCartOperationWithRetry(() => addProductToCartInternal(this.cart, cartLine, this.actionContext, input.availableQuantity, input.enableStockCheck));
        });
    }

    /**
     * Removes the cart lines with the provided ids from the cart
     *
     * @param cartLineIds The cart lines to remove
     */
    @action
    public async removeCartLines(input: {cartLineIds: string[]; additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetry(() => removeCartLineInternal(this.cart, input.cartLineIds, this.actionContext));
        });
    }

    /**
     * Clears the pickup location information from the provided cart line,
     * resulting in it getting shipped to the customer
     *
     * @param cartLineId The cart line to clear the location from
     */
    @action
    public async clearCartLinePickupLocation(input: {cartLineId: string; additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetry(() => clearCartLineDeliveryModeInternal(this.cart, input.cartLineId, this.actionContext));
        });
    }

    /**
     * Clears the delivery mode, and other information for the shipping cart lines.
     */
    @action
    public async clearCartLinesDeliveryInformation(input: { additionalProperties?: object }): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetry(() => clearCartLinesDeliveryInformation(this.cart, this.actionContext));
        });
    }

    /**
     * Marks the provided cartline for pickup in store, with the provided location as
     * the pickup location
     *
     * @param cartLineId The cart line to mark for pickup
     * @param location The location to set for pickup
     */
    @action
    public async updateCartLinePickupLocation(input: {cartLineId: string; location: OrgUnitLocation; additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            if (!this.actionContext.requestContext.channel) {
                return { status: 'FAILED' };
            }

            const cartLineDeliverySpecification = {
                LineId: input.cartLineId,
                DeliverySpecification: {
                    DeliveryModeId: this.actionContext.requestContext.channel.PickupDeliveryModeCode,
                    DeliveryPreferenceTypeValue: 2, // Pick up in store
                    PickUpStoreId: input.location.OrgUnitNumber,
                    DeliveryAddress: this._buildAddressFromOrgUnitLocation(input.location)
                }
            };

            return this._doCartOperationWithRetry(() => updateCartLineDeliverySpecificationsInternal(this.cart, [cartLineDeliverySpecification], this.actionContext));
        });
    }

    /**
     * Updates the delivery mode for items in the cart with the desired delivery mode, preserving BOPIS status for stuff already marked as BOPIS
     *
     * @param deliveryModeId The delivery mode to use
     */
    @action
    public async updateCartDeliverySpecification(input: {deliveryModeId: string; shippingAddress: Address | undefined; additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            if (input.deliveryModeId.trim() === '') {
                return { status: 'FAILED', substatus: 'EMPTYINPUT' };
            }

            const pickupDeliveryModeCode =
                this.actionContext.requestContext.channel && this.actionContext.requestContext.channel.PickupDeliveryModeCode;

            const cartLinesForShipping = (this.cart.CartLines || []).filter(
                cartLine => cartLine.DeliveryMode !== pickupDeliveryModeCode
            );

            if (cartLinesForShipping.length > 0) {
                const deliverySpecifications = cartLinesForShipping.map(cartLine => {
                    return {
                        LineId: cartLine.LineId,
                        DeliverySpecification: {
                            DeliveryModeId: input.deliveryModeId,
                            DeliveryPreferenceTypeValue: 1, // Ship
                            DeliveryAddress: input.shippingAddress
                        }
                    };
                });

                return this._doCartOperationWithRetry(() => updateCartLineDeliverySpecificationsInternal(this.cart, deliverySpecifications, this.actionContext));
            }

            return { status: 'FAILED', substatus: 'NOCONTENT' };
        });
    }

    /**
     * Updates the quantity of the cart line
     *
     * @param cartLineId The cart line to update the quantity
     * @param newQuantity The new quantity to use (note that if this exceeds max quantity, will set quantity to max quantity)
     */
    @action
    public async updateCartLineQuantity(input: {cartLineId: string; newQuantity: number; additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetry(() => updateCartLineQuantityInternal(this.cart, input.cartLineId, input.newQuantity, this.actionContext));
        });
    }

    /**
     * Updates the loyalty card ID on the card
     *
     * @param loyaltyCardId The loyalty card id to use
     */
    @action
    public async updateLoyaltyCardId(input: {loyaltyCardNumber: string | undefined; additionalProperties?: object}): Promise<ICartActionResult> {
        if (!input.loyaltyCardNumber) {
            return { status: 'FAILED', substatus: 'EMPTYINPUT' };
        }

        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetry(() => updateLoyaltyCardIdInternal(this.cart, input.loyaltyCardNumber!,  this.actionContext));
        });
    }

    /**
     * Adds promo code to the cart
     *
     * @param promoCode The promo code to add
     */
    @action
    public addPromoCode(input: {promoCode: string; additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetry(() => addPromoCodeInternal(this.cart, input.promoCode, this.actionContext));
        });
    }

    /**
     * Removes promo codes from the cart
     *
     * @param promoCodes The promo codes to remove
     */
    @action
    public removePromoCodes(input: {promoCodes: string[]; additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetry(() => removePromoCodesInternal(this.cart, input.promoCodes, this.actionContext));
        });
    }

    /**
     * Removes all promo code from the cart
     */
    @action
    public removeAllPromoCodes(input: {additionalProperties?: object}): Promise<ICartActionResult> {
        return this._doAsyncAction<ICartActionResult>(async () => {
            return this._doCartOperationWithRetry(() => removeAllPromoCodesInternal(this.cart, this.actionContext));
        });
    }

    private async _doCartOperationWithRetry(callback:() => Promise<ICartActionResultWithCart>): Promise<ICartActionResult> {
        let callbackResult = await callback();

        if (callbackResult.status === 'SUCCESS' || !this._shouldRetrySubstatus(callbackResult.substatus)) {
            if (callbackResult.cart) {
                this._cart = callbackResult.cart;
            }
        } else {
            const refreshCartResult = await this.refreshCart({});

            if (refreshCartResult.status === 'SUCCESS') {
                callbackResult = await callback();

                if (callbackResult.status === 'SUCCESS' || !this._shouldRetrySubstatus(callbackResult.substatus)) {
                    if (callbackResult.cart) {
                        this._cart = callbackResult.cart;
                    }
                }
            }
        }

        return { status: callbackResult.status, substatus: callbackResult.substatus };
    }

    private _shouldRetrySubstatus(subsatus?: ICartActionSubStatus): boolean {
        if (!subsatus) {
            return true;
        }

        // all substatus currently don't result in a retry
        return false;
    }

    private _buildAddressFromOrgUnitLocation(location: OrgUnitLocation): Address {
        return {
            RecordId: location.PostalAddressId,
            Name: location.OrgUnitName,
            FullAddress: location.Address,
            Street: location.State,
            StreetNumber: location.StreetNumber,
            City: location.City,
            DistrictName: location.DistrictName,
            BuildingCompliment: location.BuildingCompliment,
            Postbox: location.Postbox,
            ThreeLetterISORegionName: location.Country,
            ZipCode: location.Zip,
            County: location.County,
            CountyName: location.CountyName,
            State: location.State,
            StateName: location.StateName
        };
    }
}
