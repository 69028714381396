import { IActionContext } from '@msdyn365-commerce/core';
import { addCartLinesAsync, updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, CartLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';

// tslint:disable-next-line:cyclomatic-complexity
export default async function addProductToCartInternal(cart: Readonly<Cart>, cartLineToAdd: CartLine, actionContext: IActionContext, availableQuantity?: number, enableStockCheck?: boolean): Promise<ICartActionResultWithCart> {
    if (!cart.CartLines) {
        return { cart: undefined, status: 'FAILED' };
    }

    const maxQuantityForCartLineItem = actionContext.requestContext.app.config.maxQuantityForCartLineItem;
    let quantityLimit: number;

    if (enableStockCheck === false) {
        quantityLimit = maxQuantityForCartLineItem || 10;
    } else {
        if (maxQuantityForCartLineItem === undefined && availableQuantity === undefined) {
            quantityLimit = 10;
        } else if (availableQuantity === undefined) {
            quantityLimit = maxQuantityForCartLineItem;
        } else if (maxQuantityForCartLineItem === undefined) {
            quantityLimit = availableQuantity;
        } else {
            quantityLimit = Math.min(maxQuantityForCartLineItem, availableQuantity);
        }
    }

    let elementFoundAt:number = -1;
    const productIdToFind = cartLineToAdd.ProductId;

    for (let i = 0; i < cart.CartLines.length; i++) {
        if (cart.CartLines[i].ProductId === productIdToFind &&
            (cart.CartLines[i].DeliveryMode || '') === (cartLineToAdd.DeliveryMode || '') &&
            (cart.CartLines[i].FulfillmentStoreId || '') === (cartLineToAdd.FulfillmentStoreId || '')) {
            elementFoundAt = i;

            break;
        }
    }

    if (elementFoundAt !== -1) {
        const cartLineToUpdate = {...cart.CartLines[elementFoundAt]};
        const curQuantity = cartLineToUpdate.Quantity || 0;

        if (curQuantity + (cartLineToAdd.Quantity || 1) > quantityLimit) {
            return { cart: {
                Id: cart.Id,
                CartLines: cart.CartLines,
                ExtensionProperties: [{Key: 'curQuantity', Value: { IntegerValue: curQuantity }}, {Key: 'quantityLimit', Value: { IntegerValue: quantityLimit }}] }, status: 'FAILED', substatus: 'MAXQUANTITY'
            };
        }

        cartLineToUpdate.Quantity = Math.min((cartLineToUpdate.Quantity || 0) + (cartLineToAdd.Quantity || 0), quantityLimit);

        return updateCartLinesAsync({ callerContext: actionContext }, cart.Id, [cartLineToUpdate])
            .then(updatedCart => {
                return <ICartActionResultWithCart> { cart: updatedCart, status: 'SUCCESS' };
            }).catch(error => {
                actionContext.telemetry.warning(error);
                actionContext.telemetry.debug('Unable to Update Cart Line');

                return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
            });
    } else {
        const newCartLine = {...cartLineToAdd};

        if (cartLineToAdd && cartLineToAdd.Quantity && cartLineToAdd.Quantity > quantityLimit) {
            return { cart: {
                Id: cart.Id,
                CartLines: cart.CartLines,
                ExtensionProperties: [{Key: 'curQuantity', Value: { IntegerValue: 0 }}, {Key: 'quantityLimit', Value: { IntegerValue: quantityLimit }}] }, status: 'FAILED', substatus: 'MAXQUANTITY'
            };
        }

        newCartLine.Quantity = cartLineToAdd.Quantity || 1;

        if (cart.Version) {
            return addCartLinesAsync({ callerContext: actionContext }, cart.Id, [newCartLine], cart.Version)
            .then(newCart => {
                return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS' };
            }).catch(error => {
                actionContext.telemetry.trace(error);
                actionContext.telemetry.trace('Unable to add Cart Line');

                return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
            });
        } else {
            actionContext.telemetry.warning('Unable to update Cart Line, Cart Version could not be found');
        }
    }

    return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED' };
}