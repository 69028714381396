import classnames from 'classnames';
import React, { useState } from 'react';

import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { getCartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import {
    ProductAvailableQuantity, ProductDimension, SimpleProduct
} from '@msdyn365-commerce/retail-proxy';

export interface IAddToCartComponentProps extends IComponentProps<{product: SimpleProduct}> {
    className?: string;
    addToCartText: string;
    outOfStockText: string;
    disabled?: boolean;
    quantity?: number;
    navigationUrl?: string;
    productAvailability?: ProductAvailableQuantity;
    getSelectedProduct?: Promise<SimpleProduct | null>;
    onAdd?(result: ICartActionResult): void;
    onError?(result: IAddToCartFailureResult): void;
}

export declare type ICartActionFailureReason = 'EMPTYINPUT' | 'MISSINGDIMENSION' | 'OUTOFSTOCK' | 'CARTACTIONFAILED';
export interface IAddToCartFailureResult {
    failureReason: ICartActionFailureReason;

    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

export interface IAddtoCartComponent extends IComponent<IAddToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps) => void;
}

const onClick = async (_event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps, setDisabled: (disabled: boolean) => void): Promise<void> => {
    const cartError = addToCartError(props);
    let productToAdd = props.data.product;

    if (cartError) {
        propogateError(props, cartError);
        return;
    }

    setDisabled(true);

    if(!(props.getSelectedProduct === undefined)) {
        productToAdd = (await props.getSelectedProduct) || props.data.product;
    }

    const cartState = await getCartState(props.context.actionContext);
    await cartState.addProductToCart({ product: productToAdd, count: props.quantity, availableQuantity: props.productAvailability?.AvailableQuantity, enableStockCheck: props.context.app.config.enableStockCheck })
        .then(result => {
            if (result.status === 'SUCCESS') {
                if (props.navigationUrl) {
                    window.location.assign(props.navigationUrl);
                }
                propogateResult(props, result);
            } else {
                propogateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: result })
                setDisabled(false);
            }
        });
};

const AddToCartComponentActions = {
    onClick: onClick
};

const AddToCart: React.FC<IAddToCartComponentProps> = (props: IAddToCartComponentProps) => {
    const [disabled, setDisabled] = useState(false);

    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => { return AddToCartComponentActions.onClick(event, props, setDisabled); };
    return (
        <button
            className={classnames('msc-add-to-cart ', props.className)}
            aria-label={getLinkText(props)}
            onClick={onClickHandler}
            disabled={props.disabled || disabled || shouldShowOutOfStock(props, false)}
        >
            {getLinkText(props)}
        </button>
    );
};

// Set default props
AddToCart.defaultProps = {
    quantity: 1
};

const getLinkText = (props: IAddToCartComponentProps): string => {
    if (!shouldShowOutOfStock(props, false)) {
        return props.addToCartText;
    }

    return props.outOfStockText;
};

const addToCartError = (props: IAddToCartComponentProps): IAddToCartFailureResult | undefined => {
    if (!props.data || !props.data.product.RecordId) {
        // No product exists, won't be able to add to cart
        return { failureReason: 'EMPTYINPUT' };
    }

    if (props.data.product.Dimensions) {
        const missingDimensions = props.data.product.Dimensions.filter(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value));

        if (missingDimensions.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to add to cart
            return { failureReason: 'MISSINGDIMENSION', missingDimensions: missingDimensions };
        }
    }

    if (shouldShowOutOfStock(props, true)) {
        const availableQuantity = (props.productAvailability && props.productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity - props.context.app.config.outOfStockThreshold, 0);

        return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
    }

    // Only allow adding to cart if not showing out of stock
    return undefined;
};

const shouldShowOutOfStock = (props: IAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
    if (props.context.app.config.enableStockCheck === false) {
        // Out of stock turn off, don't bother showing out of stock
        return false;
    }

    if (!props.data || !props.data.product.RecordId) {
        // No product exists, don't bother showing out of stock
        return false;
    }

    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also don't show out of stock
            return false;
        }
    }

    if (props.productAvailability &&
        props.productAvailability.AvailableQuantity !== undefined &&
        props.productAvailability.AvailableQuantity >= props.context.app.config.outOfStockThreshold + (includeCurrentQuantity ? props.quantity : 1)) {
        return false;
    } else {
        // Out of stock
        return true;
    }
};

const propogateResult = (props: IAddToCartComponentProps, result: ICartActionResult): void => {
    if (props.onAdd) {
        props.onAdd(result);
    }
};

const propogateError = (props: IAddToCartComponentProps, result: IAddToCartFailureResult): void => {
    if (props.onError) {
        props.onError(result);
    }
};

// @ts-ignore
export const AddToCartComponent: React.FunctionComponent<IAddToCartComponentProps> = msdyn365Commerce.createComponent<IAddtoCartComponent>(
    'AddToCart',
    { component: AddToCart, ...AddToCartComponentActions }
);