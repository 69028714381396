import { Address, GiftCard, TenderLine, TokenizedPaymentCard, SalesOrder, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { computed, observable, action } from 'mobx';
import { GlobalState } from '../global-state/global-state';
import { ICheckoutState, ICheckoutActionResult } from '../state-interfaces/i-checkout-state';
import { ICartState } from '../state-interfaces/i-cart-state';
import { IActionContext } from '@msdyn365-commerce/core';
import { BaseCheckoutCartState } from './base-checkout-cart-state';

/**
 * State information related to what is needed to succesfully perform a checkout
 */
export class BaseCheckoutState extends GlobalState implements ICheckoutState {
    @observable protected _checkoutCart: ICartState;
    @computed public get checkoutCart(): ICartState {
        return this._checkoutCart;
    }

    @observable protected _salesOrder?: SalesOrder;
    @computed public get salesOrder(): Readonly<SalesOrder | undefined> {
        return this._salesOrder;
    }

    @observable protected _orderedProducts?: SimpleProduct[];
    @computed public get orderedProducts(): Readonly<SimpleProduct[] | undefined> {
        return this._orderedProducts;
    }

    @observable protected _tokenizedPaymentCard?: TokenizedPaymentCard;
    @computed public get tokenizedPaymentCard(): Readonly<TokenizedPaymentCard | undefined> {
        return this._tokenizedPaymentCard;
    }

    @observable protected _tenderLine?: TenderLine;
    @computed public get tenderLine(): Readonly<TenderLine | undefined> {
        return this._tenderLine;
    }

    @observable protected _billingAddress?: Address;
    @computed public get billingAddress(): Readonly<Address | undefined> {
        return this._billingAddress;
    }

    @observable protected _shippingAddress?: Address;
    @computed public get shippingAddress(): Readonly<Address | undefined> {
        return this._shippingAddress;
    }

    @observable protected _cardPrefix?: string;
    @computed public get cardPrefix(): Readonly<string | undefined> {
        return this._cardPrefix;
    }

    @observable protected _giftCards: GiftCard[] = [];
    @computed public get giftCards(): Readonly<GiftCard[]> {
        return this._giftCards;
    }

    @observable protected _loyaltyCardNumber?: string;
    @computed public get loyaltyCardNumber(): Readonly<string | undefined> {
        return this._loyaltyCardNumber;
    }

    @observable protected _loyaltyAmount: number = 0;
    @computed public get loyaltyAmount(): Readonly<number> {
        return this._loyaltyAmount;
    }

    @observable protected _guestCheckoutEmail: string = '';
    @computed public get guestCheckoutEmail(): Readonly<string> {
        return this._guestCheckoutEmail;
    }

    constructor(actionContext: IActionContext) {
        super(actionContext);
        this._checkoutCart = new BaseCheckoutCartState(actionContext);
    }

    public async initialize(): Promise<void> {
        if (this.isInitialized) {
            return;
        }

        await this._checkoutCart.initialize();

        this.isInitialized = true;
        this._status = 'READY';
    }

    @action
    public updateSalesOrder(input: {newSalesOrder: SalesOrder; newOrderedProducts: SimpleProduct[]; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._salesOrder = input.newSalesOrder;
        this._orderedProducts = input.newOrderedProducts;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateTokenizedPaymentCard(input: {newTokenizedPaymentCard: TokenizedPaymentCard; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._tokenizedPaymentCard = input.newTokenizedPaymentCard;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateTenderLine(input: {newTenderLine?: TenderLine; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._tenderLine = input.newTenderLine;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateShippingAddress(input: {newShippingAddress: Address; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._shippingAddress = input.newShippingAddress;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateBillingAddress(input: {newBillingAddress: Address; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._billingAddress = input.newBillingAddress;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateCardPrefix(input: {newCardPrefix: string; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._cardPrefix = input.newCardPrefix;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public removeGiftCard(input: {giftCardNumber: string; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._giftCards = this._giftCards.filter(giftCard => giftCard.Id !== input.giftCardNumber);

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public addGiftCard(input: {giftCard: GiftCard; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._giftCards = [input.giftCard, ...this._giftCards];

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateLoyaltyCardNumber(input: {newLoyaltyCardNumber: string; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._loyaltyCardNumber = input.newLoyaltyCardNumber;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateLoyaltyAmount(input: {newAmount: number; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._loyaltyAmount = Number(input.newAmount.toFixed(2));

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }

    @action
    public updateGuestCheckoutEmail(input: {newGuestCheckoutEmail: string; additionalProperties?: object}): Promise<ICheckoutActionResult> {
        this._guestCheckoutEmail = input.newGuestCheckoutEmail;

        return Promise.resolve(<ICheckoutActionResult>{ status: 'SUCCESS' });
    }
}