import { IActionContext } from '@msdyn365-commerce/core';
import { updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { Cart, CartLine } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICartActionResultWithCart } from './cart-action-result';
import _ from 'lodash';

export default async function clearCartLinesDeliveryInformation(cart: Readonly<Cart | undefined>, actionContext: IActionContext): Promise<ICartActionResultWithCart> {

    if (!cart || !cart.CartLines) {
        return { cart: undefined, status: 'FAILED'};
    }

    const pickupDeliveryModeCode = actionContext && actionContext.requestContext && actionContext.requestContext.channel?.PickupDeliveryModeCode;

    const matchingLines = cart.CartLines.filter(
        cartLine => cartLine.DeliveryMode && cartLine.DeliveryMode !== pickupDeliveryModeCode && cartLine.LineId
    );

    // If there are no lines needing updates, just return success
    if (matchingLines.length === 0) {
        return <ICartActionResultWithCart> { cart: cart, status: 'SUCCESS'};
    }

    let copiedLines: CartLine[] = _.cloneDeep(matchingLines);

    copiedLines.forEach( line => {
        line.DeliveryMode = '';
        line.FulfillmentStoreId = '';
        line.ShippingAddress = {};
    });

    return updateCartLinesAsync({ callerContext: actionContext}, cart.Id, copiedLines)
        .then((newCart: any) => {
            return <ICartActionResultWithCart> { cart: newCart, status: 'SUCCESS'};
        })
        .catch((error: string) => {
            actionContext.telemetry.warning(error);
            actionContext.telemetry.debug('Unable to Update Cart Lines');

            return <ICartActionResultWithCart> { cart: undefined, status: 'FAILED'};
        });
}